<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle
					class="btn_bottom_sheet"
					v-model="selected"
					:mandatory="mandatory"
				>
					<div class="u-sheet-btns">
						<v-btn
							class="btn_select_type center"
							depressed
							outlined
							v-for="(item, i) in items"
							:ripple="false"
							:key="`item-${i}`"
							@click="onClick(i)"
						>
							<em>{{ item.text | comma }}</em
							><em v-if="item.code !== '0'">원</em>
							<em class="btn_value" v-if="item.code !== '0'">
								<b class="ml-1 primary--text">
									-{{ Math.round(item.code) | comma }}원/월
								</b>
							</em>
						</v-btn>
					</div>

					<div class="u-pop-message" v-if="showType(product)">
						<ul>
							<li>
								<p class="info">
									렌탈료를 선납하는 경우, 할인 적용 됩니다.
									<br />(부가서비스, 별매상품 포함) <br />
								</p>
							</li>
						</ul>
					</div>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
/**
 * 선납할인2 선택 화면이다.
 * @description PayPageSale.vue 화면에서 사용중이다.
 */
import filters from '@/mixins/filters'

export default {
	name: 'PopDownRentalBottomSheet',
	mixins: [filters],
	props: {
		value: [String, Number],
		product: {
			type: Object,
			default: () => {
				return []
			}
		},
		prdMonthTotal: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false },
		downRentalSelected: Object
	},

	computed: {
		displayText() {
			return this.text
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},

	data() {
		return {
			seen: false,
			items: [],
			preAmt: {
				preAmtTotal: 0,
				preAmt: 0,
				preAmtSale: 0,
				preAmtMonth: 0,
				preSaleMonth: 0
			},
			selectedItem: {},
			selected: -1
		}
	},

	methods: {
		async open() {
			this.selectedItem = {}
			this.selected = -1
			await this.downRentalAmtInfo()

			if (this.downRentalSelected) {
				this.items.forEach((v, i) => {
					if (v.code === this.downRentalSelected.code) {
						this.selected = i
						this.selectedItem = v
					}
				})
			}
			await this.prepaySelectedCheck()
		},

		prepaySelectedCheck() {
			if (
				this.product.payOptions.festaActive ||
				this.product.payOptions.fifteenActive
			) {
				this.seen = false
				this.showAlert()
				return
			}
			if (
				this.product.payOptions.ET_ZSDS6087.length > 0 &&
				this.product.payOptions.prepayMonth !== '01'
			) {
				this.seen = false
				this.showAlert()
			} else if (this.product.payOptions.promotion) {
				const zsd5204 = this.product.payOptions.ET_ZSDS5204.filter(
					v =>
						v.PRM_CODE === this.product.payOptions.promotion &&
						v.DOWN_YN !== 'Y'
				)

				if (zsd5204.length > 0) {
					this.seen = false
					this.showAlert()
				} else {
					this.seen = true
				}
			} else {
				this.seen = true
			}
		},
		showAlert() {
			this.$alert({
				message:
					'선택되어있는 할인제도와 중복적용 할 수 없습니다. 판매 정책을 확인해주세요'
			})
		},
		// 출력여부
		showType(product) {
			let result = true
			if (
				product.payOptions.mattressInfo &&
				product.payOptions.mattressInfo.selectMattress !== null &&
				(product.payOptions.mattressInfo.typeCd === 'M' ||
					product.payOptions.mattressInfo.typeCd === 'MF')
			) {
				result = false
			} else {
				result = true
			}
			return result
		},

		async downRentalAmtInfo() {
			this.items = []
			this.items = [
				{
					code: '0',
					text: '선납할인2 할인 선택 없음',
					preAmt: 0,
					monthAmt: 0,
					PREGB: '',
					PRE_AMT: '0'
				}
			]

			this.product.payOptions.ET_ZSDS6087.forEach(v => {
				this.items.push({
					code: v.MONTH_DC,
					text: v.PRE_AMT,
					PRE_AMT: v.PRE_AMT,
					DC_AMT: v.DC_AMT,
					TOT_AMT: v.TOT_AMT,
					MONTH_DC: v.MONTH_DC,
					MONTH_DOWN: v.MONTH_DOWN,
					RET_YEARS: v.RET_YEARS,
					PREGB: v.PREGB
				})
			})
		},

		onClick(e) {
			this.$log('TEST', this.items[e])
			this.selectedItem = this.items[e]
			this.$emit('item-selected', this.selectedItem)
			this.seen = false
		}
	}
}
</script>
